var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12" },
                  },
                  [
                    _c("v-select", {
                      staticClass: "el-def",
                      staticStyle: { width: "540px" },
                      attrs: {
                        options: _vm.codes.insertBannerType,
                        placeholder: "배너 유형",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.insertBannerType,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "insertBannerType", $$v)
                        },
                        expression: "query.insertBannerType",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-1" },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12", md: "10" },
                  },
                  [
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: { placeholder: "게시기간(Fr)" },
                      model: {
                        value: _vm.query.startDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "startDate", $$v)
                        },
                        expression: "query.startDate",
                      },
                    }),
                    _c("label", { staticClass: "ml-25 mr-50" }, [_vm._v("~")]),
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: { placeholder: "게시기간(To)" },
                      model: {
                        value: _vm.query.endDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "endDate", $$v)
                        },
                        expression: "query.endDate",
                      },
                    }),
                    _c("v-select", {
                      staticClass: "el-def",
                      attrs: {
                        options: _vm.codes.postStatus,
                        placeholder: "게시 상태",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.postStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "postStatus", $$v)
                        },
                        expression: "query.postStatus",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { cols: "12", md: "2" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-search",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.refetchData()
                          },
                        },
                      },
                      [_vm._v(" 조회 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("h5", { staticClass: "mb-0 ml-50" }, [
                        _vm._v(
                          " 등록 리스트 [" + _vm._s(_vm.totalRecords) + "] 건 "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            ref: "refBannerTable",
            staticClass: "position-relative",
            attrs: {
              "primary-key": "id",
              items: _vm.fetchBanners,
              fields: _vm.tableColumns,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.isSortDesc,
              responsive: "",
              "sticky-header": "",
              selectable: "",
              "select-mode": "single",
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.isSortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.isSortDesc = $event
              },
              "row-selected": _vm.onRowSelectedBanner,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(bannerType)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.item.bannerTypeName) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(contentType)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(" " + _vm._s(data.item.contentTypeName) + " "),
                    ]),
                  ]
                },
              },
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(startDate)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("$dateFormatter")(data.value, "YYYY-MM-DD")
                          ) +
                          " ~ " +
                          _vm._s(
                            _vm._f("$dateFormatter")(
                              data.item.endDate,
                              "YYYY-MM-DD"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "cell(postStatus)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(" " + _vm._s(data.item.postStatusName) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mt-2" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "EditIcon", size: "19" },
                  }),
                  _c("h4", { staticClass: "mb-0 ml-50" }, [_vm._v(" 상세 ")]),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center justify-content-end",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.resetBannerData()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "PlusSquareIcon" },
                      }),
                      _c("span", [_vm._v("신규 등록")]),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.saveBanner()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "SaveIcon" },
                      }),
                      _c("span", [_vm._v("저장")]),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeBanner()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "Trash2Icon" },
                      }),
                      _c("span", [_vm._v("삭제")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "validation-observer",
            { ref: "formRules" },
            [
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "배너 유형",
                            "label-for": "banner-type",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "배너 유형", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        options: _vm.codes.insertBannerType,
                                        placeholder: "배너유형",
                                        reduce: function (option) {
                                          return option.code
                                        },
                                        state: errors.length > 0 ? false : null,
                                      },
                                      on: { change: _vm.changeBannerType },
                                      model: {
                                        value: _vm.bannerData.bannerType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.bannerData,
                                            "bannerType",
                                            $$v
                                          )
                                        },
                                        expression: "bannerData.bannerType",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "게시여부",
                            "label-for": "banner-is-use",
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "banner-is-use",
                              options: _vm.codes.useTypeFixed,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            model: {
                              value: _vm.bannerData.isUse,
                              callback: function ($$v) {
                                _vm.$set(_vm.bannerData, "isUse", $$v)
                              },
                              expression: "bannerData.isUse",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "콘텐츠 유형 선택",
                            "label-for": "banner-content-type",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "콘텐츠 유형 선택",
                              rules: "required",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-radio-group", {
                                      attrs: {
                                        options:
                                          _vm.codes.insertBannerContentType,
                                        "value-field": "code",
                                        "text-field": "label",
                                      },
                                      on: { change: _vm.changeContentType },
                                      model: {
                                        value: _vm.bannerData.contentType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.bannerData,
                                            "contentType",
                                            $$v
                                          )
                                        },
                                        expression: "bannerData.contentType",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "8", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "콘텐츠 선택",
                            "label-for": "banner-content-title",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "콘텐츠", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "banner-content-title",
                                        state: errors.length > 0 ? false : null,
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.bannerData.title,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.bannerData, "title", $$v)
                                        },
                                        expression: "bannerData.title",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-50", attrs: { cols: "4", md: "2" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-0 mt-md-2",
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openBannerContentSelect($event)
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "SearchIcon" },
                          }),
                          _c("span", [_vm._v("목록")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-start justify-content-center",
                      attrs: { cols: "12", md: "4" },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "게시 상태",
                            "label-for": "survey-allow-join-type",
                          },
                        },
                        [
                          _c(
                            "b-form-radio-group",
                            {
                              attrs: {
                                "value-field": "code",
                                "text-field": "label",
                              },
                            },
                            _vm._l(_vm.codes.postStatus, function (item) {
                              return _c(
                                "b-form-radio",
                                {
                                  key: item.code,
                                  attrs: {
                                    value: item.code,
                                    checked: _vm.bannerData.postStatus,
                                    disabled:
                                      item.code !== _vm.bannerData.postStatus,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "8" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "배너 등록",
                            "label-for": "banner-image-file",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "b-card-text",
                                { staticClass: "my-50 text-primary" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.bannerData.fileName) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("b-form-file", {
                            attrs: { accept: "image/*" },
                            on: { input: _vm.readBannerImage },
                            model: {
                              value: _vm.bannerImage,
                              callback: function ($$v) {
                                _vm.bannerImage = $$v
                              },
                              expression: "bannerImage",
                            },
                          }),
                          _c("validation-provider", {
                            attrs: { name: "배너이미지", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: false,
                                          expression: "false",
                                        },
                                      ],
                                      model: {
                                        value: _vm.bannerData.fileName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.bannerData,
                                            "fileName",
                                            $$v
                                          )
                                        },
                                        expression: "bannerData.fileName",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c("label", [_vm._v("게시 기간")]),
                  ]),
                  _c(
                    "b-col",
                    { attrs: { cols: "6", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "",
                            "label-for": "banner-start-date",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "게시시작일",
                              rules: {
                                required: true,
                                before: _vm.bannerData.endDate,
                                dateBetween: [
                                  _vm.bannerData.contentStartDate,
                                  _vm.bannerData.contentEndDate,
                                ],
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  var failedRules = ref.failedRules
                                  return [
                                    _c("b-form-datepicker", {
                                      attrs: {
                                        id: "banner-start-date",
                                        placeholder: "게시시작일",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.bannerData.startDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.bannerData,
                                            "startDate",
                                            $$v
                                          )
                                        },
                                        expression: "bannerData.startDate",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              failedRules.hasOwnProperty(
                                                "dateBetween"
                                              )
                                                ? "게시시작일은 콘텐츠 게시기간 이내로 제한됩니다."
                                                : errors[0]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: { label: "", "label-for": "banner-end-date" },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "게시종료일",
                              rules: {
                                required: true,
                                after: _vm.bannerData.startDate,
                                dateBetween: [
                                  _vm.bannerData.contentStartDate,
                                  _vm.bannerData.contentEndDate,
                                ],
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  var failedRules = ref.failedRules
                                  return [
                                    _c("b-form-datepicker", {
                                      attrs: {
                                        id: "banner-end-date",
                                        placeholder: "게시종료일",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.bannerData.endDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.bannerData,
                                            "endDate",
                                            $$v
                                          )
                                        },
                                        expression: "bannerData.endDate",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              failedRules.hasOwnProperty(
                                                "dateBetween"
                                              )
                                                ? "게시종료일은 콘텐츠 게시기간 이내로 제한됩니다."
                                                : errors[0]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("insert-banner-content-select", {
        attrs: {
          "content-type": _vm.bannerData.contentType,
          "show-banner-content-select": _vm.showBannerContentSelect,
        },
        on: { close: _vm.bannerContentSelect.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }